<template>
<Layout>
  <h1>{{season.name}}</h1>

  <v-row class="mx-2">
    <v-col cols="12"  xl="3" lg="4" md="4" sm="6"
           v-for="(episode,i) in episodes"
           :key="i">
      <Episode :episode="episode" :season="season" :tv="tv"></Episode>
  </v-col>
</v-row>
</Layout>
</template>

<script>
import Layout from '../Layout';
import Episode from '../components/Episode';
import firebase from 'firebase/app';
import 'firebase/firestore';
// import { moviedb } from '../variables';

export default {
  metaInfo () {
    return {
      title: this.tv.name + ' Season ' + this.season.season_number
    };
  },
  components: {
    Layout,
    Episode
  },
  data () {
    return {
      tv: {},
      season: {},
      episodes: [],
      snapshots: []
    };
  },
  methods: {
    imgObj: function (path) {
      return {
        src: 'https://assets.le-navet.com/static/800' + path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + path
      };
    }
  },
  created: function () {
    let tvId = this.$route.params.id;
    let seasonNumber = this.$route.params.season;
    let firestore = firebase.firestore();
    let tvRef = firestore.collection('tv');
    tvRef.doc(tvId).get().then(snapshot => {
      this.tv = snapshot.data();
    });
    tvRef.doc(tvId).collection('seasons').where('season_number', '==', parseInt(seasonNumber)).get().then(snapshot => {
      snapshot.forEach(doc => {
        this.season = doc.data();
        tvRef.doc(tvId).collection('seasons').doc(this.season.id + '').collection('episodes').orderBy('episode_number', 'asc').get().then(snapshotQuery => {
          snapshotQuery.forEach(episode => {
            this.episodes.push(episode.data());
            /*
            moviedb.tvEpisodeExternalIds({id: this.season.id, season_number: parseInt(seasonNumber), episode_number: episode.data().episode_number}).then(value => {
              console.log(value);
            });
            */
          });
        });
      });
    });
  }
}
</script>

<style scoped>

</style>
